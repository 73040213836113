import React, { useEffect, useRef, useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import paypal from "../assets/payment/paypal.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faCommentDollar,
  faChalkboardUser,
  faAward,
  faArrowLeftLong,
  faCartShopping,
  faCircleUser,
  faCreditCard
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { apiUrl } from "../config/Config";
import PayPalButton from "../components/PaypalButton";
import Overlay from "../components/Overlay";

const Cart = () => {
  const location = useLocation();
    const navigate = useNavigate();

  const { categoryslug, 
    courseslug,
    coursename,
    type, 
    price, 
    country,
    city,
    startdate,
    enddate,
    starttime,
    endtime,
    weektype
  } = location.state || {};

    const [cardsarr, setcardsarr] = useState([
        {name: 'ordersummary', isvisibility: true, isshow: true},
        {name: 'learnerdetails', isvisibility: false, isshow: false},
        {name: 'payment', isvisibility: false, isshow: false},
    ])

    const [couponcode, setcouponcode] = useState("");
    const [couponcoderesponse, setcouponcoderesponse] = useState({'status': "", 'message': "", 'isvisble': ""})
    const [totalprice, settotalprice] = useState(price);
    const [showloading, setshowloading] = useState(false);
    const couponcoderesponseRef = useRef();
    couponcoderesponseRef.current = couponcoderesponse;
    const [forceupdatestate, setforceupdatestate] = useState(false);

    const [cartdata, setcartdata] = useState({
      categoryslug: categoryslug,
      courseslug: courseslug,
      coursename: coursename,
      type: type,
      price: price,
      country: country,
      city: city,
      startdate: startdate,
      enddate: enddate,
      starttime: starttime,
      endtime: endtime,
      weektype: weektype,
      couponcode: "",
      coupondiscount: 0,
      totalprice: price,
      firstname: "",
      lastname: "",
      email: "",
      alternateemail: "",
      mobile: "",
      alternatemobile: "",
      address: "",
      refcode: "",
      payid: ""
    });

    const cartdataRef = useRef({});
    cartdataRef.current = cartdata

    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [femail, setfemail] = useState("");
    const [falternateemail, setfalternateemail] = useState("");
    const [fmobile, setfmobile] = useState("");
    const [falternatemobile, setfalternatemobile] = useState("");
    const [fcity, setfcity] = useState("");
    const [faddress, setfaddress] = useState("");
    const [refcode, setrefcode] = useState("");

    
    const cardsarrRef = useRef([]);
    cardsarrRef.current = cardsarr;

    const handletoggleblock = (name) => {
      var prevdata = cardsarrRef.current;
      var newarr = [];
      for(var i = 0; i < prevdata?.length;i++) {
        var obj = prevdata[i];
        if(prevdata[i].name == name) {
          obj['isshow'] = true
        } else {
          obj['isshow'] = false;
        }
        newarr.push(obj);
      }
      console.log('newarr >>', newarr)
      setcardsarr(newarr)
    }

    const submitcartdetails = () => {
      var prevdata = cardsarrRef.current;
      prevdata[0]['isshow'] = false;
      prevdata[1]['isshow'] = true;
      prevdata[1]['isvisibility'] = true;
      setcardsarr(prevdata)
      setforceupdatestate(!forceupdatestate)
    }

    const handlecouponsubmit = async() => {
      // alert('coupon');
      if (couponcode) {
        setshowloading(true);
        var data = {
            coupon: couponcode,
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "applycartcoupon", data, headers)
          .then((res) => {
            console.log('res >>>', res.data.data)
            if (res.data.status == "success") {
              if(res.data.data?.length > 0) {
                setcouponcoderesponse({
                  'status': "success", 
                    'message': "Coupon " + res.data.data[0]?.code + " applied successfully!!", 
                    'isvisble': true
                })
                var totalprice = price - parseInt(res.data.data[0]?.amount);
                settotalprice(totalprice);
                setcouponcode("");

                var prevdata = cartdataRef.current;
                prevdata['couponcode'] = res.data.data[0]?.code;
                prevdata['coupondiscount'] = parseInt(res.data.data[0]?.amount);
                prevdata['totalprice'] = totalprice;
                setcartdata(prevdata);
                setforceupdatestate(!forceupdatestate);
                
              } else {
                setcouponcoderesponse({
                  'status': "failed", 
                    'message': "Invalid Coupon", 
                    'isvisble': true
                })
                settotalprice(price);
                var prevdata = cartdataRef.current;
                prevdata['couponcode'] = "";
                prevdata['coupondiscount'] ="";
                prevdata['totalprice'] = price;
                setcartdata(prevdata);
                setforceupdatestate(!forceupdatestate)
              }
              setshowloading(false);
            } else {
              alert(res.data.message);
              setshowloading(false);
            }
          })
          .catch((err) => {
            setshowloading(false);
            alert(err.message);
          });
          setshowloading(false);
      } else {
        alert("Empty Field");
      }
    }

    const handlesubmitlarnerdetails = async() => {
      if(firstname && lastname && femail && fmobile ) {
        var prevdata = cardsarrRef.current;
        prevdata[1]['isshow'] = false;
        prevdata[2]['isshow'] = true;
        prevdata[2]['isvisibility'] = true;
        setcardsarr(prevdata)

      var prevdata1 = cartdataRef.current;
      prevdata1['firstname'] = firstname;
      prevdata1['lastname'] = lastname;
      prevdata1['email'] = femail;
      prevdata1['alternateemail'] = falternateemail;
      prevdata1['mobile'] = fmobile;
      prevdata1['alternatemobile'] = falternatemobile;
      prevdata1['address'] = faddress;
      prevdata1['refcode'] = refcode;
      setcartdata(prevdata1);

      setforceupdatestate(!forceupdatestate)
      } else {
        alert("Empty Fields");
      }
    }

    const handleSuccess = async(details) => {
      console.log('Transaction completed by', details.payer.name.given_name);
      console.log('details >>>', details);


      var prevdata = cartdataRef.current;
      prevdata['payid'] = details?.id;

      setshowloading(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "paymentsuccessfull", prevdata, headers)
          .then((res) => {
            console.log('res >>>', res.data.data)
            if (res.data.status == "success") {
              navigate('/thankyou', { replace: true });
              setshowloading(false);
            } else {
              navigate('/thankyou', { replace: true });
              // alert(res.data.message);
              setshowloading(false);
            }
          })
          .catch((err) => {
            navigate('/thankyou', { replace: true });
            // setshowloading(false);
            alert(err.message);
          });
          setshowloading(false);



      

    };

    const handleCancel = async(data) => {
      console.log('Payment cancelled', data);
      // alert('Payment Cancelled');


      // var prevdata = cartdataRef.current;
      // prevdata['payid'] = 100;

      // setshowloading(true);
      //   const headers = {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   };
      //   let resp = await axios
      //     .post(apiUrl + "paymentsuccessfull", prevdata, headers)
      //     .then((res) => {
      //       console.log('res >>>', res.data.data)
      //       if (res.data.status == "success") {
      //         // navigate('/thankyou', { replace: true });
      //         setshowloading(false);
      //       } else {
      //         // navigate('/thankyou', { replace: true });
      //         // alert(res.data.message);
      //         setshowloading(false);
      //       }
      //     })
      //     .catch((err) => {
      //       // navigate('/thankyou', { replace: true });
      //       // setshowloading(false);
      //       alert(err.message);
      //     });
      //     setshowloading(false);
    };

    

    useEffect(() => {
      if(categoryslug) {
        console.log('categoryslug >>', categoryslug);
        console.log('country >>>', country);
      } else {
        navigate("/");
      }
    }, [])

    console.log('couponcoderesponseRef.current >>', couponcoderesponseRef.current);
    console.log('cartdataRef.current >>', cartdataRef.current);

    

    return (
        <div>
          <Overlay showloader={showloading} />
            <Header />
            <div className="course-details-topborder"></div>
            <div className="cartsection">
                <div className="pagewidth">
                    <div className="backtocourse">
                        <Link className="cartbacklink" to={'/courses/'+categoryslug+'/'+courseslug}>
                            <FontAwesomeIcon icon={faArrowLeftLong} className="cartbackicon" />
                            <p className="backtocoursetext">Back To Course</p>
                        </Link>
                    </div>
                    <div className="cartmaindiv">
                        {
                            cardsarrRef.current?.map((item, index) => {
                                return (
                                  <div className="cartmaindiv1">
                                    {item?.name == "ordersummary" ? (
                                      <div className="cartbox">
                                        <div className="ordersummary-box">
                                          <div className="iconcircle">
                                            <FontAwesomeIcon
                                              icon={faCartShopping}
                                              className="iconcircle-icon"
                                            />
                                          </div>
                                          <p className="ordersummar-head">
                                            Order Summary
                                          </p>
                                          {
                                            item.isshow == true || item.show == 'true' ?
                                            null :
                                            <div className="editdetailsdiv" onClick={() => {handletoggleblock('ordersummary')}}>
                                              <p className="editdetails-p">Edit Details</p>
                                            </div>
                                          }
                                        </div>
                                        {
                                          item.isshow == true || item.show == 'true' ?
                                        <div className="row ordersummary-courserow">
                                          <div className="col-12 col-md-6">
                                            <div className="ordersummary-coursebox">
                                              <p className="ordersummary-course-head">
                                                {coursename ? coursename : ""}
                                              </p>
                                              <hr className="ordersummary-course-hr" />
                                              <p className="ordersummary-course-schedulehead">
                                                Schedule details
                                              </p>
                                              <div className="ordersummary-course-schedulediv">
                                                <div className="ordersummary-course-schedulerow">
                                                  <p className="ordersummary-course-schedulerowkey">
                                                    Type :
                                                  </p>
                                                  <p className="ordersummary-course-schedulerowvalue">
                                                    {type ? type : ""}
                                                  </p>
                                                </div>
                                                {
                                                  startdate && enddate ?
                                                  <div className="ordersummary-course-schedulerow">
                                                  <p className="ordersummary-course-schedulerowkey">
                                                    Schedule :
                                                  </p>
                                                  <p className="ordersummary-course-schedulerowvalue">
                                                    {startdate} - {enddate}
                                                  </p>
                                                </div> : null
                                                }

                                                {
                                                  starttime && endtime ?
                                                  <div className="ordersummary-course-schedulerow">
                                                  <p className="ordersummary-course-schedulerowkey">
                                                    Time :
                                                  </p>
                                                  <p className="ordersummary-course-schedulerowvalue">
                                                    {starttime} - {endtime}
                                                  </p>
                                                </div> : null
                                                }
                                                
                                                {
                                                  country ?
                                                  <div className="ordersummary-course-schedulerow">
                                                  <p className="ordersummary-course-schedulerowkey">
                                                    Country :
                                                  </p>
                                                  <p className="ordersummary-course-schedulerowvalue">
                                                    {country}
                                                  </p>
                                                </div> : null
                                                }

                                                {
                                                  city ?
                                                  <div className="ordersummary-course-schedulerow">
                                                  <p className="ordersummary-course-schedulerowkey">
                                                    City :
                                                  </p>
                                                  <p className="ordersummary-course-schedulerowvalue">
                                                    {city}
                                                  </p>
                                                </div> : null
                                                } 
                                                
                                                <div className="ordersummary-course-schedulerow">
                                                  <p className="ordersummary-course-schedulerowkey">
                                                    No. of participants :
                                                  </p>
                                                  <p className="ordersummary-course-schedulerowvalue">
                                                    1
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="ordersummary-btn" onClick={() => {submitcartdetails()}}>
                                              {
                                                cardsarrRef.current[1]['isvisibility'] == true ?
                                                <p>Update</p> :
                                                <p>Continue</p>
                                              }
                                              
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-6">
                                            <div className="ordersummary-rightdiv">
                                              <p className="ordersummary-course-schedulerowkey">
                                                Coupon Code
                                              </p>
                                              <div className="ordersummary-inputrow">
                                                <input
                                                value={couponcode}
                                                onChange={(t) => {
                                                  setcouponcode(t.target.value);
                                                }}
                                                  type="text"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Coupon Code"
                                                />
                                                <div className="ordersummary-btn1" style={couponcode?.length > 0 ? {opacity: 1} : {opacity: 0.5}} onClick={() => 
                                                  {
                                                    if(couponcode?.length > 0) {
                                                      handlecouponsubmit()
                                                    }
                                                }}>
                                                  <p>Apply Coupon</p>
                                                </div>
                                              </div>
                                              {
                                                couponcoderesponseRef.current?.isvisble == true ?
                                                couponcoderesponseRef.current?.status == 'success' ?
                                                <p className="successcouponmsg">{couponcoderesponseRef.current?.message}</p> :
                                                <p className="failedcouponmsg">{couponcoderesponseRef.current?.message}</p>
                                                : null
                                              }
                                              <div className="ordersummary-pricerow">
                                                <p className="ordersummary-totalprice">
                                                  Total Price :
                                                </p>
                                                <p
                                                  className="ordersummary-totalprice"
                                                  style={{ fontWeight: 700 }}
                                                >
                                                  USD {price ? price : 0}
                                                </p>
                                              </div>
                                              {couponcoderesponseRef.current?.status == 'success' ?
                                              <div className="ordersummary-pricerow">
                                              <p className="ordersummary-totalprice">
                                                Coupon Discount :
                                              </p>
                                              <p
                                                className="ordersummary-totalprice"
                                                style={{ fontWeight: 700 }}
                                              >
                                                - {price ? price - totalprice : 0}
                                              </p>
                                            </div> : null
                                            }
                                              <hr className="ordersummary-course-hr" />
                                              <div className="ordersummary-pricerow">
                                                <p className="ordersummary-totalprice ordersummary-totalprice1">
                                                  Grand Total :
                                                </p>
                                                <p
                                                  className="ordersummary-totalprice ordersummary-totalprice1"
                                                  style={{ fontWeight: 700 }}
                                                >
                                                  USD {totalprice}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div> : null 
                            }
                                      </div>
                                    ) : item?.name == "learnerdetails" ? (
                                      <div className="cartbox">
                                        <div className="ordersummary-box" >
                                          <div className="iconcircle">
                                            <FontAwesomeIcon
                                              icon={faCircleUser}
                                              className="iconcircle-icon"
                                            />
                                          </div>
                                          <p className="ordersummar-head">
                                            Learner Details
                                          </p>
                                          {
                                            item.isshow == true || item.show == 'true' ?
                                            null :
                                            item.isvisibility == true ?
                                            <div className="editdetailsdiv" onClick={() => {handletoggleblock('learnerdetails')}}>
                                              <p className="editdetails-p">Edit Details</p>
                                            </div> : null
                                          }
                                          
                                        </div>
                                        {
                                          item.isshow == true || item.show == 'true' ?
                                          <div className="learnerdetails-maindiv">
                                          <div className="row">
                                            <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  First Name{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    *
                                                  </span>
                                                </p>
                                                <input
                                                  value={firstname}
                                                  onChange={(t) => {setfirstname(t.target.value)}}
                                                  type="text"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter First Name"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Last Name{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    *
                                                  </span>
                                                </p>
                                                <input
                                                  value={lastname}
                                                  onChange={(t) => {setlastname(t.target.value)}}
                                                  type="text"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Last Name"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Email Address{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    *
                                                  </span>
                                                </p>
                                                <input
                                                value={femail}
                                                onChange={(t) => {setfemail(t.target.value)}}
                                                  type="email"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Email Address"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Alternate Email Address
                                                </p>
                                                <input
                                                value={falternateemail}
                                                onChange={(t) => {setfalternateemail(t.target.value)}}
                                                  type="email"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Alternate Email Address"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Phone Number{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    *
                                                  </span>
                                                </p>
                                                <input
                                                value={fmobile}
                                                onChange={(t) => {setfmobile(t.target.value)}}
                                                  type="number"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Phone number"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Alternate Phone Number
                                                </p>
                                                <input
                                                value={falternatemobile}
                                                onChange={(t) => {setfalternatemobile(t.target.value)}}
                                                  type="number"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Alternate Phone number"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Address{" "}
                                                </p>
                                                <textarea
                                                style={{height: 80}}
                                                rows={5}
                                                value={faddress}
                                                onChange={(t) => {setfaddress(t.target.value)}}
                                                  type="text"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Address"
                                                ></textarea>
                                              </div>
                                            </div>
                                            {/* <div className="col-12 col-md-6">
                                              <div className="learner-inputdiv">
                                                <p className="ordersummary-course-schedulerowkey">
                                                  Referral Code (optional)
                                                </p>
                                                <input
                                                value={refcode}
                                                onChange={(t) => {setrefcode(t.target.value)}}
                                                  type="number"
                                                  className="form-control order-summaryinput"
                                                  placeholder="Enter Referal Code"
                                                />
                                              </div>
                                            </div> */}
                                          </div>
                                          <div className="ordersummary-btn" onClick={() => {handlesubmitlarnerdetails()}}>
                                            {
                                                cardsarrRef.current[2]['isvisibility'] == true ?
                                                <p>Update</p> :
                                                <p>Continue to Payment</p>
                                              }
                                          </div>
                                          </div> : 
                                          // <div className="learnerdetails-maindiv"></div>
                                          null
                                        }
                                        
                                      </div>
                                    ) : item?.name == "payment" ? (
                                      <div className="cartbox">
                                        <div className="ordersummary-box">
                                          <div className="iconcircle">
                                            <FontAwesomeIcon
                                              icon={faCircleUser}
                                              className="iconcircle-icon"
                                            />
                                          </div>
                                          <p className="ordersummar-head">
                                            Payment Details
                                          </p>
                                          {
                                            item.isshow == true || item.show == 'true' ?
                                            null :
                                            item.isvisibility == true ?
                                            <div className="editdetailsdiv" onClick={() => {handletoggleblock('payment')}}>
                                              <p className="editdetails-p">Proceed To Pay $ {cartdataRef.current['totalprice']}</p>
                                            </div> : null
                                          }
                                        </div>
                                        {
                                          item.isshow == true || item.show == 'true' ?
                                          <div className="paymentdetails-maindiv">
                                          <p className="ordersummary-course-schedulerowkey car-payment-text">
                                            Select one of the payment gateway to
                                            continue
                                          </p>
                                          <div className="payment-box-maindiv">
                                            <div className="payment-box">
                                                <img src={paypal} className="paypal-logo" />
                                                {/* <div className="ordersummary-btn">
                                                    <p>Pay With Paypal</p>
                                                </div> */}
                                                <PayPalButton amount={cartdataRef.current['totalprice']}  onSuccess={handleSuccess} onCancel={handleCancel} height={45} />
                                                {/* <PayPalButton amount={1}  onSuccess={handleSuccess} onCancel={handleCancel} height={45} /> */}
                                                <p className="ordersummary-course-schedulerowkey paypal-bottom-text">
                                                  Major Credit / Debit Cards Accepted
                                                </p>
                                            </div>
                                          </div>
                                          <p className="ordersummary-course-schedulerowkey car-payment-text" style={{width: '100%'}}>
                                            By placing this order, you confirm that you have read Upskills Certifications Terms and Conditions, Refund Policy and Privacy Policy.
                                          </p>
                                        </div> : 
                                        null
                                        }
                                        
                                      </div>
                                    ) : null}
                                  </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cart