import React, { useEffect, useState,useCallback,
    useRef,
    StrictMode, } from "react";
  import Sidebar from "../../components/Sidebar";
  import { useSelector, useDispatch } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { apiUrl } from "../../config/Config";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPenToSquare, faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
  import { Button, Modal } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  import Overlay from "../../components/Overlay";
  import { useMemo } from 'react';
  import { AgGridReact } from "@ag-grid-community/react";
  import "@ag-grid-community/styles/ag-grid.css";
  import "@ag-grid-community/styles/ag-theme-quartz.css";
  import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
  import { ModuleRegistry } from "@ag-grid-community/core";
  import Select from 'react-select'

  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  
  const Batches = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [cityname, setcityname] = useState("");
    const [countryname, setcountryname] = useState("");
    const [selectedid, setselectedid] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const paginationPageSizeSelector = useMemo(() => {
      return [10, 20, 50, 100];
    }, []);

    const [categoryname, setcategoryname] = useState("");
    const [course, setcourse] = useState("");
    const [batchtype , setbatchtype ] = useState("");
    const [country , setcountry ] = useState("");
    const [cities , setcities ] = useState("");
    const [price , setprice ] = useState("");
    const [offerprice , setofferprice ] = useState("");
    const [startingdate , setstartingdate ] = useState("");
    const [endingdate , setendingdate ] = useState("");
    const [startingtime , setstartingtime ] = useState("");
    const [endingtime , setendingtime ] = useState("");
    const [weektype, setweektype ] = useState("");

    const [categorydata, setcategorydata] = useState([]);
    const [coursedata, setcoursedata] = useState([]);
    const [citydata, setcitydata] = useState([]);

    const options = [
        { value: 'India', label: 'India' },
        { value: 'United States of America', label: 'United States of America' },
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Australia', label: 'Australia' },
      ];

    const batchoptions = [
      { value: 'In-Person Classroom', label: 'In-Person Classroom' },
      { value: 'Live Online Classroom', label: 'Live Online Classroom' }
    ]

    const weekoptions = [
      {value: 'Weekdays', label: 'Weekdays'},
      {value: 'Weekend', label: 'Weekend'}
    ]
  
    const defaultColDef = {
      flex: 1,
    };
  
    const clickhandle = () =>  {
      console.log('Mission Launched')
    }
    
    const fetchallcourses = async () => {
      setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "getallbatches", headers)
        .then((res) => {
          if (res.data.status == "success") {
            // console.log('getallbatches >>', res.data);
            var prevdata = res.data.data;
            if(prevdata?.length > 0) {
              var columnsarr = [];
              var hidecolumns = ['id', 'updated_at','created_at', 'sort','category', 'title', 'categoryname', 'country', 'city', 'starttime', 'endtime', 'status', 'price'];
              var hidefilters = [];
              var columns = Object.keys(prevdata[0]);
              for(var i = 0; i < columns?.length;i++) {
                if(!hidecolumns.includes(columns[i])) {
                  var obj = {};
                  obj.field = columns[i];
                  if(!hidefilters.includes(columns[i])) {
                    obj.filter = true;
                    obj.filterParams = {
                        filterOptions: ["contains"],
                        maxNumConditions: 1,
                    };

                  }
                //   console.log('obj >>', obj)
                  columnsarr.push(obj);
                }
              }
              //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
              // clone
              var editobj = 
              {
                field: "clone",
                headerName: "Clone",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            clonebatch(props?.data);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
              //delete
              var deleteobj = 
              {
                field: "delete",
                headerName: "Delete",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            deletecourse(props?.data?.id);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="sidebaricons"
                            style={{ color: "red" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(deleteobj);
              setColDefs(columnsarr);
              setRowData(prevdata);
            }
            
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
        setshowloding(false);
    };

    const fetchallcategories = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "getallcoursecategories", headers)
        .then((res) => {
          if (res.data.status == "success") {
            // console.log('getallcoursecategories >>', res.data);
            var prevdata = res.data.data;
            if(prevdata?.length > 0) {
              var newarr = [];
              for(var i = 0; i < prevdata?.length; i++) {
                var obj = {};
                obj['label'] = prevdata[i].categoryname;
                obj['value'] = prevdata[i].slug;
                newarr.push(obj)
              }
              setcategorydata(newarr);
            }
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const deletecourse = async (id) => {
      const result = window.confirm("Are you sure you want to delete this batch ?");
      if (result) {
        var data = JSON.stringify({
          id: id,
        });
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "newdeletebatch", data, headers)
          .then((res) => {
            if (res.data.status == "success") {
              fetchallcourses();
            } else {
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        // User clicked Cancel or closed the dialog
        console.log("User clicked Cancel");
      }
    };
    const submithandle = async () => {
      var citiesarr = [];
      if(cities?.length > 0) {
        if(cities.find((option) => option.value === "select-all")) {
          for(var i = 0; i < citydata?.length; i++) {
            citiesarr.push(citydata[i].label)
          }
        } else {
          for(var i = 0; i < cities?.length; i++) {
            citiesarr.push(cities[i].label)
          }
        }
        
      }
      var data = {
        categoryname: categoryname?.value,
        course: course?.value,
        batchtype: batchtype?.value,
        country: country?.value,
        city: citiesarr,
        price: price,
        offerprice: offerprice,
        startdate: startingdate,
        enddate: endingdate,
        starttime: startingtime,
        endtime: endingtime,
        weektype: weektype?.value
      }
      console.log('data >>', data);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "newaddnewbatch", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              setshowmodal(false);
              setcategoryname("");
              setcourse("");
              setbatchtype("");
              setcountry("");
              setcities([]);
              setprice("");
              setofferprice("");
              setstartingdate("");
              setendingdate("");
              setstartingtime("");
              setendingtime("");
              setweektype("");
              setselectedid(0);
              // setcityname("");
              // setcountryname("");
              fetchallcourses();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      
    };
  
    const editlocation = async(data) => {
      setselectedid(data?.id);
      console.log('data >>', data);
      var categoryobj = { 'value': data?.category, 'label': data?.categoryname };
      setcategoryname(categoryobj);
      var courseobj = { 'value': data?.course, 'label': data?.title };
      setcourse(courseobj);
      var batchobj = { 'value': data?.batchtype, 'label': data?.batchtype };
      setbatchtype(batchobj);
      var countryobj = { 'value': data?.country, 'label': data?.country };
      setcountry(countryobj);
      var weektypeobj = { 'value': data?.weektype, 'label': data?.weektype };
      setweektype(weektypeobj);
      setprice(data?.price);
      setofferprice(data?.offerprice);
      setstartingdate(data?.startdate)
      setendingdate(data?.enddate)
      setstartingtime(data?.starttime)
      setendingtime(data?.endtime)
      if(data?.city) {
        var citiesarr = data?.city?.split("-");
        if(citiesarr?.length > 0) {
          if(citiesarr.find((option) => option === "All Cities")) {
            var newcities = [];
            var obj = { value: "select-all", label: "All Cities" };
            newcities.push(obj);
            setcities(newcities);
          } else {
             var newcities = [];
          for(var i = 0; i < citiesarr?.length;i++) {
            var obj = { 'value': citiesarr[i], 'label': citiesarr[i] };
            newcities.push(obj);
          }
          setcities(newcities);
          }
         
        } else {
          setcities([]);
        }
      } else {
        setcities([]);
      }

      seteditshowmodal(true);
    }

    const clonebatch = async(data) => {
      // setselectedid(data?.id);
      console.log('data >>', data);
      var categoryobj = { 'value': data?.category, 'label': data?.categoryname };
      setcategoryname(categoryobj);
      var courseobj = { 'value': data?.course, 'label': data?.title };
      setcourse(courseobj);
      var batchobj = { 'value': data?.batchtype, 'label': data?.batchtype };
      setbatchtype(batchobj);
      var countryobj = { 'value': data?.country, 'label': data?.country };
      setcountry(countryobj);
      var weektypeobj = { 'value': data?.weektype, 'label': data?.weektype };
      setweektype(weektypeobj);
      setprice(data?.price);
      setofferprice(data?.offerprice);
      setstartingdate(data?.startdate)
      setendingdate(data?.enddate)
      setstartingtime(data?.starttime)
      setendingtime(data?.endtime)
      if(data?.city) {
        var citiesarr = data?.city?.split("-");
        if(citiesarr?.length > 0) {
          if(citiesarr.find((option) => option === "All Cities")) {
            var newcities = [];
            var obj = { value: "select-all", label: "All Cities" };
            newcities.push(obj);
            setcities(newcities);
          } else {
             var newcities = [];
          for(var i = 0; i < citiesarr?.length;i++) {
            var obj = { 'value': citiesarr[i], 'label': citiesarr[i] };
            newcities.push(obj);
          }
          setcities(newcities);
          }
         
        } else {
          setcities([]);
        }
      } else {
        setcities([]);
      }
      setshowmodal(true);
      // seteditshowmodal(true);
    }
  
    const submithandle1 = async () => {
      setshowloding(true);
      var citiesarr = [];
      if(cities?.length > 0) {
        if(cities.find((option) => option.value === "select-all")) {
          for(var i = 0; i < citydata?.length; i++) {
            citiesarr.push(citydata[i].label)
          }
        } else {
          for(var i = 0; i < cities?.length; i++) {
            citiesarr.push(cities[i].label)
          }
        }
        
      }
      var data = {
        id: selectedid,
        categoryname: categoryname?.value,
        course: course?.value,
        batchtype: batchtype?.value,
        country: country?.value,
        city: citiesarr,
        price: price,
        offerprice: offerprice,
        startdate: startingdate,
        enddate: endingdate,
        starttime: startingtime,
        endtime: endingtime,
        weektype: weektype?.value
      }
      console.log('data >>', data);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "newupdatebatch", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setcategoryname("");
              setcourse("");
              setbatchtype("");
              setcountry("");
              setcities([]);
              setprice("");
              setofferprice("");
              setstartingdate("");
              setendingdate("");
              setstartingtime("");
              setendingtime("");
              setweektype("");
              setselectedid(0);
              // setcityname("");
              // setcountryname("");
              // fetchallcourses();
              fetchallcourses();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
    };

    const handlechange = (selectedOption) => {
        setcountryname(selectedOption)
    }

    const fetchcoursebycategory = async(category) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        catid: category?.value
      }
      let resp = await axios
        .post(apiUrl + "getcoursenamebycategory",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            // console.log('getallcoursecategories >>', res.data);
            var prevdata = res.data.data;
            // setcourse("")
            if(prevdata?.length > 0) {
              var newarr = [];
              for(var i = 0; i < prevdata?.length; i++) {
                var obj = {};
                obj['label'] = prevdata[i].title;
                obj['value'] = prevdata[i].slug;
                newarr.push(obj)
              }
              setcoursedata(newarr);
            } else {
              setcoursedata([]);
              
            }
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const fetchcitiesbycountry = async(city) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        catid: city?.value
      }
      let resp = await axios
        .post(apiUrl + "getcitiesbycountry",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            // console.log('getallcoursecategories >>', res.data);
            var prevdata = res.data.data;
            // setcities("")
            if(prevdata?.length > 0) {
              var newarr = [{ value: "select-all", label: "All Cities" }];
              for(var i = 0; i < prevdata?.length; i++) {
                var obj = {};
                obj['label'] = prevdata[i].city;
                obj['value'] = prevdata[i].city;
                newarr.push(obj)
              }
              setcitydata(newarr);
            } else {
              setcitydata([]);
              
            }
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const handleChange = (selected) => {
      console.log('selected >>', selected);
      if (selected && selected.find((option) => option.value === "select-all")) {
        if (selected.length === citydata.length) {
          // Deselect all if all options are selected
          setcities([]);
        } else {
          // Select all options
          setcities(citydata.slice(1)); // Exclude "Select All"
        }
      } else {
        setcities(selected || []);
      }
      setcities(selected);
    };

    const getDisplayValue = () => {
      if (cities.length === citydata.length - 1) {
        return [{ value: "select-all", label: "All Cities" }, ...cities];
      }
      return cities;
    };

    useEffect(() => {
      if(categoryname) {
        fetchcoursebycategory(categoryname);
      } else {

      }
    }, [categoryname])

    useEffect(() => {
      if(country) {
        fetchcitiesbycountry(country);
      } else {

      }
    }, [country])
  
   
    useEffect(() => {
      if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchallcourses();
        fetchallcategories();
      } else {
        navigate("/newadminlogin");
      }
    }, []);


    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <div className="mainDiv">
            <p className="oneplaceHead">BATCHES LIST</p>
            <br />
            <div className="tablebuttondiv tablebuttondivpage">
              <Link
                onClick={() => {
                  setcategoryname("");
              setcourse("");
              setbatchtype("");
              setcountry("");
              setcities([]);
              setprice("");
              setofferprice("");
              setstartingdate("");
              setendingdate("");
              setstartingtime("");
              setendingtime("");
              setweektype("");
              setselectedid(0);
                  setshowmodal(true);
                }}
                className="btndiv webadminmaindiv"
              >
                <p>{"Add Batch"}</p>
              </Link>
            </div>

            <div
              className={"ag-theme-quartz"}
              style={{ width: "100%", height: 550 }}
            >
              <AgGridReact
                // modules={AllModules}
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                groupSelectsChildren={true}
                rowSelection={"multiple"}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                // onGridReady={onGridReady}
              />
            </div>

            {/*Add modal */}
            <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="xl"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 19 }}>New Batch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  <div className="row">
                    <div className="col-md-3">
                      <label>Category</label>
                      <Select
                        value={categoryname}
                        onChange={setcategoryname}
                        options={categorydata}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Course</label>
                      <Select
                        value={course}
                        onChange={setcourse}
                        options={coursedata}
                      />
                    </div>

                    <div className="col-md-3">
                      <label>Batch type</label>
                      <Select
                        value={batchtype}
                        onChange={setbatchtype}
                        options={batchoptions}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-3">
                      <label>Select Country</label>
                      <Select
                        value={country}
                        onChange={setcountry}
                        options={options}
                      />
                    </div>
                    <div className="col-md-5">
                      <label>Select City</label>
                      <Select
                      options={citydata}
                      isMulti
                      closeMenuOnSelect={false}
                        value={getDisplayValue()}
                        onChange={handleChange}
                        
                      />
                    </div>
                    <div className="col-md-2">
                    <label>Price ($)</label>
                    <input
                      value={price}
                      onChange={(t) => {
                        setprice(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Price in $"
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Offer Price ($)</label>
                    <input
                      value={offerprice}
                      onChange={(t) => {
                        setofferprice(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Offer Price in $"
                    />
                  </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-3">
                    <label>Starting From</label>
                    <input
                      value={startingdate}
                      onChange={(t) => {
                        setstartingdate(t.target.value);
                      }}
                      type="date"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Ending At</label>
                    <input
                      value={endingdate}
                      onChange={(t) => {
                        setendingdate(t.target.value);
                      }}
                      type="date"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Starts At</label>
                    <input
                      value={startingtime}
                      onChange={(t) => {
                        setstartingtime(t.target.value);
                      }}
                      type="time"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Ends At</label>
                    <input
                      value={endingtime}
                      onChange={(t) => {
                        setendingtime(t.target.value);
                      }}
                      type="time"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  </div>

                  <div className="row mt-4 mb-3">
                    <div className="col-md-3">
                      <label>Week Type</label>
                      <Select
                        value={weektype}
                        onChange={setweektype}
                        options={weekoptions}
                      />
                    </div>
                  </div>

                  

                  {/* <div className="">
                    <label>City</label>
                    <input
                      value={cityname}
                      onChange={(t) => {
                        setcityname(t.target.value);
                      }}
                      type="text"
                      className="form-control tabinput"
                      placeholder="Enter City name"
                    />
                  </div> */}
                  <div
                    className="webadmin-modalbtn mt-5 mb-4"
                    onClick={() => {
                      submithandle();
                    }}
                  >
                    <p>Add Batch</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/*Edit modal */}
            <Modal
              show={editshowmodal}
              onHide={() => seteditshowmodal(false)}
              size="xl"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="modaltitle">Edit Batch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="webadmin-modalmainbody">
                  <div className="row">
                    <div className="col-md-3">
                      <label>Category</label>
                      <Select
                        value={categoryname}
                        onChange={setcategoryname}
                        options={categorydata}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Course</label>
                      <Select
                        value={course}
                        onChange={setcourse}
                        options={coursedata}
                      />
                    </div>

                    <div className="col-md-3">
                      <label>Batch type</label>
                      <Select
                        value={batchtype}
                        onChange={setbatchtype}
                        options={batchoptions}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-3">
                      <label>Select Country</label>
                      <Select
                        value={country}
                        onChange={setcountry}
                        options={options}
                      />
                    </div>
                    <div className="col-md-5">
                      <label>Select City</label>
                      <Select
                      isMulti
                      closeMenuOnSelect={false}
                        value={cities}
                        onChange={setcities}
                        options={citydata}
                      />
                    </div>
                    <div className="col-md-2">
                    <label>Price ($)</label>
                    <input
                      value={price}
                      onChange={(t) => {
                        setprice(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Price in $"
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Offer Price ($)</label>
                    <input
                      value={offerprice}
                      onChange={(t) => {
                        setofferprice(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Offer Price in $"
                    />
                  </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-3">
                    <label>Starting From</label>
                    <input
                      value={startingdate}
                      onChange={(t) => {
                        setstartingdate(t.target.value);
                      }}
                      type="date"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Ending At</label>
                    <input
                      value={endingdate}
                      onChange={(t) => {
                        setendingdate(t.target.value);
                      }}
                      type="date"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Starts At</label>
                    <input
                      value={startingtime}
                      onChange={(t) => {
                        setstartingtime(t.target.value);
                      }}
                      type="time"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Ends At</label>
                    <input
                      value={endingtime}
                      onChange={(t) => {
                        setendingtime(t.target.value);
                      }}
                      type="time"
                      className="form-control tabinput"
                      placeholder=""
                    />
                  </div>
                  </div>

                  <div className="row mt-4 mb-3">
                    <div className="col-md-3">
                      <label>Week Type</label>
                      <Select
                        value={weektype}
                        onChange={setweektype}
                        options={weekoptions}
                      />
                    </div>
                  </div>

                  

                  {/* <div className="">
                    <label>City</label>
                    <input
                      value={cityname}
                      onChange={(t) => {
                        setcityname(t.target.value);
                      }}
                      type="text"
                      className="form-control tabinput"
                      placeholder="Enter City name"
                    />
                  </div> */}
                  <div
                    className="webadmin-modalbtn mt-5 mb-4"
                    onClick={() => {
                      submithandle1();
                    }}
                  >
                    <p>Edit Batch</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  
  export default Batches;
  