import React from 'react';
import { Route, Link, BrowserRouter as Router, Routes } from 'react-router-dom';
import payment from '../assets/payment/payments-logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
    return (
        <div className='footermaindiv'>
            <div className="pagewidth">
                <div className='footerinnermaindiv'>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Company</p>
                        <div className='footerlinksdiv'>
                            <Link to={'/about-us'}>About Us</Link>
                            <Link to={'/corporate-training'}>Corporate Training</Link>
                            <Link to={'/trainers'}>Trainers</Link>
                            <Link to={'/contact-us'}>Contact Us</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Quick Links</p>
                        <div className='footerlinksdiv'>
                            <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            <Link to={'/refund-policy'}>Refund Policy</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Popular Courses</p>
                        <div className='footerlinksdiv'>
                            <Link to={'/courses/Project-Management/PMP-Certification-Training'}>PMP® Certification Training</Link>
                            <Link to={'/courses/Project-Management/CAPM-Certification-Training'}>CAPM® Certification Training</Link>
                            <Link to={'/courses/Project-Management/PgMP-Certification-Training'}>PgMP® Certification Training</Link>
                            <Link to={'/courses/IT-Service-and-Architecture/ITIL-4-Foundation-Training'}>ITIL 4 Foundation Training</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Get in Touch</p>
                        <div className='footerlinksdiv'>
                            <Link to={'https://www.facebook.com/UpskillsCertifications/'} target='_blank'><FontAwesomeIcon icon={faFacebook} className='footericons' /> Facebook</Link>
                            <Link to={'https://x.com/upskillscert'} target='_blank'><FontAwesomeIcon icon={faTwitter} className='footericons' /> Twitter</Link>
                            <Link to={'https://www.youtube.com/@upskillscertifications'} target='_blank'><FontAwesomeIcon icon={faYoutube} className='footericons' /> YouTube</Link>
                            <Link to={'https://www.instagram.com/upskillscertifications/'} target='_blank'><FontAwesomeIcon icon={faInstagram} className='footericons' /> Instagram</Link>
                        </div>
                    </div>
                    <div className='footerdiv1'>
                        <p className='footerhead'>Secure Payments</p>
                        <div className='footerlinksdiv'>
                            <div className='payment-strip'>
                                <img src={payment} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyrightdiv'>
                    <p className='copyrighttext'>Copyright 2024 © Upskills Certifications LLC. All rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}